"use client";

import { Button } from "@/components/ui/button";
import { postevent } from "@/lib/events";
import Logger from "@/utils/log";
import { Flex } from "@radix-ui/themes";
import Link from "next/link";
import posthog from "posthog-js";
import { useEffect } from "react";

const log = new Logger("top-level-error-handler");

export default function Error({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  useEffect(() => {
    log.error(error?.message);
    posthog.capture(postevent.ERROR_HANDLER_TOP_LEVEL, {
      error: error?.message,
      stack: error?.stack,
    });
  }, [error]);

  return (
    <div className="grid h-screen place-content-center bg-background px-4">
      <div className="text-center">
        <h1 className="text-9xl font-black text-gray-200">404</h1>

        <p className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">Uh-oh!</p>

        <p className="mt-4 text-gray-500">We can&apos;t find that page.</p>

        <Flex className="mt-20 space-x-4">
          <Button onClick={() => reset()} variant="secondary">
            Try again
          </Button>

          <Link href="/">
            <Button className="primary">Go Back Home</Button>
          </Link>
        </Flex>
      </div>
    </div>
  );
}
